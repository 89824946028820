import React from 'react';
import { Helmet } from "react-helmet-async";
import StepData from "../components/Funnel/StepData";

const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>Select you device</title>
        <meta
          name="description"
          content="Select you device"
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <StepData step={1} routeNext={`/pricing`} />
    </>
  );
};

export default Pricing;
