import React from "react";
import { Button } from "../UI";
import { featuresConfig } from "./config";

import cn from "classnames";
import styles from "./FeaturesSection.module.scss";

const FeaturesSection = () => {
  return (
    <section className={styles["features"]} id="features">
      <div className={"wrapper"}>
        <div className={styles["features__wrapper"]}>
          <div className={styles["features__items"]}>
            <div className={styles["features__image"]}>
              <img
                className={styles["image"]}
                srcSet={
                  "/images/home/features.png 1x, /images/home/features@2x.png 2x"
                }
                src={"/images/home/features.png"}
                alt="Detect spyware on your phone"
              />
            </div>
            <div className={styles["features__description"]}>
              <h2 className={cn(styles["features__title"], "main-title")}>
                Detect spyware on your phone
              </h2>
              <ul className={styles["features__list"]}>
                {featuresConfig.map(({ icon, title }, i) => {
                  return (
                    <li
                      className={styles["features__list-item"]}
                      key={`feature-${i}`}
                    >
                      {icon && <span>{icon}</span>}
                      <span>{title}</span>
                    </li>
                  );
                })}
              </ul>
              <Button
                className={styles["button"]}
                to="/device"
                rel="nofollow"
              >
                Get started
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
