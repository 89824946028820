import React from "react";

import cn from "classnames";
import styles from "./ProductSection.module.scss";

const ProductSection = () => {
  return (
    <section className={styles["product"]} id="product">
      <div className={"wrapper"}>
        <h2 className={"main-title"}>A trusted anti-spy app for iPhone</h2>
        <p className={"main-subtitle"}>Here's how we ensure your security</p>
        <div className={styles["product__items"]}>
          <div
            className={cn(
              styles["product__item"],
              styles["product__item-first"],
            )}
          >
            <h3 className={styles["title"]}>Device System Check</h3>
            <p className={styles["description"]}>
              Device system check. Scans your iPhone's system for security
              vulnerabilities like outdated operating system or jailbreaking
              attempts.
            </p>
            <div className={styles["comment"]}>
              <img
                className={styles["comment__image"]}
                srcSet={
                  "/images/home/product-1.png 1x, /images/home/product-1@2x.png 2x"
                }
                src={"/images/home/product-1.png"}
                width="74"
                height="74"
                alt="why-1"
              />
              <p className={styles["comment__author"]}>Anna</p>
              <p className={styles["comment__text"]}>
                "This app helped me discover and fix a major security risk I
                didn't even know I had. Highly recommend!"
              </p>
            </div>
          </div>

          <div
            className={cn(
              styles["product__item"],
              styles["product__item-second"],
            )}
          >
            <h3 className={styles["title"]}>Data Breach Monitor</h3>
            <p className={styles["description"]}>
              Constantly monitors for data breaches and alerts you if your
              personal information is compromised.
            </p>
            <div className={styles["comment"]}>
              <img
                className={styles["comment__image"]}
                srcSet={
                  "/images/home/product-2.png 1x, /images/home/product-2@2x.png 2x"
                }
                src={"/images/home/product-2.png"}
                width="74"
                height="74"
                alt="why-2"
              />
              <p className={styles["comment__author"]}>John</p>
              <p className={styles["comment__text"]}>
                "The peace of mind this app provides is priceless. I was
                notified of a breach before I even knew it happened."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSection;
