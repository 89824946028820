const plansConfig = [
  {
    name: "Premium",
    isMostPopular: true,
    periods: [
      {
        code: "SMPOM",
        title: "1 month access",
        isActive: true,
        price: {
          current: '$48.99',
          full: ''
        }
      },
      {
        code: "SMPO3",
        title: "3 months access",
        isActive: true,
        price: {
          current: '$83.99',
          full: ''
        }
      },
      {
        code: "SMPOY",
        title: "Lifetime access",
        isActive: true,
        price: {
          current: '$139.99',
          full: ''
        }
      }
    ],
    features: [
      'Scan for spyware and suspicious apps',
      'Optimize your device so it feels new',
      'Secure your Wi-Fi',
      'Protect your info from data breaches',
      'Get advice for better privacy'
    ]
  },
  {
    name: "Extreme",
    isMostPopular: false,
    periods: [
      {
        code: "SMPOEM",
        title: "1 month access",
        isActive: true,
        price: {
          current: '$69.99',
          full: ''
        }
      },
      {
        code: "SMPOE3",
        title: "3 months access",
        isActive: true,
        price: {
          current: '$135.00',
          full: ''
        }
      },
      {
        code: "SMPOEL",
        title: "Lifetime access",
        isActive: true,
        price: {
          current: '$287.88',
          full: ''
        }
      }
    ],
    features: [
      '<strong>Everything in Premium</strong>',
      'Lock down your social media',
      'Detect hidden cameras',
      'Stop identity theft',
      'Scan and block dangerous websites'
    ]
  }
];

export { plansConfig };
