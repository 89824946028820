import React, { useRef, useState } from "react";
import { faqConfig } from "./config";
import { CollapseExpand, IconCirclePlus, IconClose } from "../UI";

import cn from "classnames";
import styles from "./FAQSection.module.scss";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const bodyBlock = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={
        isOpen
          ? cn(styles["list-item"], styles["is-open"])
          : styles["list-item"]
      }
    >
      <div className={styles["faq-question"]} onClick={toggleOpen}>
        <h3 className={styles["faq__item-title"]}>{question}</h3>
        <button onClick={toggleOpen}>
          {isOpen ? <IconClose /> : <IconCirclePlus />}
        </button>
      </div>
      <CollapseExpand isOpen={isOpen}>
        <div className={styles["faq-item"]} ref={bodyBlock}>
          <p className={styles["faq-answer"]}>{answer}</p>
        </div>
      </CollapseExpand>
    </div>
  );
};

const FAQSection = () => {
  return (
    <section className={styles["faq-section"]}>
      <div className={"wrapper"}>
        <h2 className={"main-title"}>FAQ</h2>
        <div className={styles["list"]}>
          {faqConfig.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
