const faqConfig = [
  {
    question: 'Do you offer a free trial?',
    answer: 'Currently, we don\'t offer a trial virsion of our app. To access all spyware removal tools within Secure My Phone Anti Spy, you must sign up for a subscription.',
  },
  {
    question: 'Is there a desktop version of the app?',
    answer: 'Secure My Phone Anti Spy is primarily designed for iPhone use and is an ultimate tool for comprehensive security coverage across all your apps and accounts. It is curretly available for download only through the App Store.',
  },
];

export { faqConfig };
