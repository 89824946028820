import {useNavigate} from "react-router-dom";
import { Button } from "../../UI";
import { devicePageConfig as data } from "./config";

import cn from 'classnames';
import styles from './StepData.module.scss';
import React, {useEffect, useState} from "react";
import StepsSection from "../StepsSection";

const CheckElem = ({ isChecked, handleChange, item }) => {
  const { title, value } = item;

  return (
    <div
      className={cn(
        styles['check__item'],
        { [styles['isActive']]: isChecked  },
        { [styles['specify']]: value === 'dont' },
      )}
      onClick={handleChange}
    >
      <div className={styles['check__item-box']}></div>
      <span>{title}</span>
    </div>
  );
};

const StepData = ({ step, routeNext }) => {
  const navigate = useNavigate();
  const [selection, setSelection] = useState({});

  useEffect(() => {
    const initialSelection = data.items.reduce((acc, item) => {
      acc[item.value] = false;
      return acc;
    }, {});
    setSelection(initialSelection);
  }, []);

  const handleChange = (item) => {
    const { value } = item;
    setSelection((prev) => ({
      ...prev,
      [value]: !selection[value],
    }));
  };

  const isButtonDisabled = !Object.values(selection).some(Boolean);

  const moveNextStep = () => {
    navigate(`${routeNext}`);
  };

  return (
    <>
      <div className={styles['funnel-data']} id="funnel">
        <div className={cn('wrapper', styles['funnel-data__wrapper'])}>
          <StepsSection active={step} />
          <h2 className={cn(styles["funnel-data__title"], "main-title")}>
            {data.title}
          </h2>
          <p className={styles["funnel-data__desc"]}>
            {data.description}
          </p>
          <div className={styles['check__list']}>
            {data.items &&
              data.items.map((item, i) =>
                <CheckElem
                  item={item}
                  key={i}
                  handleChange={() => handleChange(item)}
                  isChecked={!!selection[item.value]}
                />
              )}
            <Button
              type="button"
              className={cn(styles['funnel-data__button'])}
              aria-label={data.button}
              disabled={isButtonDisabled}
              onClick={moveNextStep}
            >
              {data.button}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepData;
