import cn from "classnames";
import styles from "./Nav.module.scss";
import { Link } from "react-router-dom";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { navConfig } from "./config.js";
import { scrollToElement } from "../../utils/scrollToElement";
import { useState } from "react";

const Nav = ({ isNavOpen, onNavClose, isHideDesktop }) => {
  const isMobile = useMediaQuery("(max-width: 991px)");
  const [activeItem, setActiveItem] = useState("");

  const handleScroll = (id) => (event) => {
    if (isNavOpen) {
      onNavClose();
    }
    scrollToElement(id, isMobile)(event);
    setActiveItem(id);
  };

  return (
    <nav
      className={cn(
        styles["nav"],
        { [styles["is-opened"]]: isNavOpen },
        { [styles["is-hide-desktop"]]: isHideDesktop },
      )}
    >
      <ul className={styles["nav-items"]}>
        {navConfig.map(({ href, text, moveToId }) => {
          return (
            <li
              key={href || text}
              className={cn(styles["nav-item"], {
                [styles["active"]]: activeItem === moveToId,
              })}
            >
              <Link
                to={href}
                onClick={handleScroll(moveToId)}
                className={styles["nav-link"]}
                aria-label={text}
              >
                <span className={styles["nav-text"]}>{text}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export { Nav };
