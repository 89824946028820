import { IconDone, IconDownload, IconScan } from "../UI";
import React from "react";

const getStartedConfig = [
  {
    icon: <IconDownload />,
    title: "Step 1",
    text: " Install the Secure My Phone anti-spy app",
  },
  {
    icon: <IconDone />,
    title: "Step 2",
    text: "Select the best plan for you",
  },
  {
    icon: <IconScan />,
    title: "Step 3",
    text: "Scan your device",
  },
];

export { getStartedConfig };
