import cn from "classnames";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { scrollToElement } from "../../utils/scrollToElement";

const Policy = ({ pageDate }) => {
  const contentRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const { REACT_APP_DOMAIN_PROD, REACT_APP_SHOP_EMAIL_SUPPORT, REACT_APP_SHOP_EMAIL_DPO } = process.env;

  const pageContent = {
    ...pageDate,
    text: pageDate.text
      .replaceAll("{brand}", REACT_APP_DOMAIN_PROD)
      .replaceAll("{email}", REACT_APP_SHOP_EMAIL_SUPPORT)
      .replaceAll("{emailDpo}", REACT_APP_SHOP_EMAIL_DPO)
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("contents__link")) {
        event.preventDefault();
        const id = event.target.getAttribute("href").replace("#", "");
        scrollToElement(id, isMobile, 10)(event);
      }
    };

    const currentRef = contentRef.current;
    currentRef.addEventListener("click", handleClick);
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("click", handleClick);
      }
    };
  }, [isMobile]);

  return (
    <section className="policy-section">
      <div className={cn("policy-section__wrapper", "wrapper")}>
        <h1
          className={"policy-section__title"}
          dangerouslySetInnerHTML={{
            __html: pageContent.title,
          }}
        />

        <div
          className="policy-section__text"
          ref={contentRef}
          dangerouslySetInnerHTML={{
            __html: pageContent.text,
          }}
        />
      </div>
    </section>
  );
};

export default Policy;
