import React from 'react';
import {Link, useLocation} from "react-router-dom";

import cn from "classnames";
import styles from "./Header.module.scss";

const SimpleHeader = () => {
  const { pathname } = useLocation();
  const isWhiteBG = ["/device"].includes(pathname);

  return (
    <header className={cn(styles["header-logo"], {[styles["bg-white"]]: isWhiteBG})}>
      <div className={cn("wrapper", styles["header-logo__wrapper"])}>
        <Link to="/" className={styles["header-logo__image"]}>
          <img
            src={"/images/logo.svg"}
            width="225"
            height="58"
            alt="Secure my phone - logo"
          />
        </Link>
      </div>
    </header>
  );
};

export default SimpleHeader;