import { IconAccount, IconDMB, IconSpyware } from "../UI";
import React from "react";

const featuresConfig = [
  {
    icon: <IconSpyware />,
    title: "Eliminate spyware",
  },
  {
    icon: <IconDMB />,
    title: "Monitor potential data breaches",
  },
  {
    icon: <IconAccount />,
    title: "Identify unauthorized access attempts",
  },
  {
    title: "and more...",
  },
];

export { featuresConfig };
