import React from "react";

import cn from "classnames";
import styles from "./StepsSection.module.scss";

const StepsSection = ({ active, stepsCount = 3, className }) => {
  return (
    <div className={cn(styles["steps"], className)}>
      <div className={cn("wrapper", styles["steps__wrapper"])}>
        <ul className={styles["steps__list"]}>
          {Array.from({ length: stepsCount }, (_, index) => (
            <li
              key={index}
              className={active - 1 === index ? styles.isActive : ""}
            >
              Step {index + 1}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default StepsSection;
