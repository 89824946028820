import { Button } from "../UI";
import cn from "classnames";
import styles from "./HeroSection.module.scss";

const HeroSection = () => {
  return (
    <section className={styles["hero"]}>
      <div className={cn("wrapper", styles["hero__wrapper"])}>
        <div className={styles["hero__main-container"]}>
          <h1 className={cn(styles["hero__title"], "title")}>
            <span>Secure My Phone:</span> <br />
            The Ultimate iPhone Protection App
          </h1>
          <p className={styles["hero__description"]}>
            Safeguard your iPhone from spyware, data breaches, and unauthorized access.
          </p>
          <Button
            className={styles["hero__button"]}
            to="/device"
            rel="nofollow"
          >
            Get started
          </Button>
        </div>
        <picture className={styles["hero__picture"]}>
          <img
            className={styles["hero__image"]}
            srcSet={"/images/home/hero.png 1x, /images/home/hero@2x.png 2x"}
            src={"/images/home/hero.png"}
            width="604"
            height="499"
            alt="Hero"
          />
        </picture>
      </div>
    </section>
  );
};

export default HeroSection;
