import { IconAntiSpy, IconChat, IconDoneFull, IconSecure } from "../UI";
import React from "react";

const whyConfig = [
  {
    icon: <IconAntiSpy />,
    title: "Time-tested reputation",
  },
  {
    icon: <IconDoneFull />,
    title: "Сross-platform compatibility",
  },
  {
    icon: <IconSecure />,
    title: "Extensive list of features",
  },
  {
    icon: <IconChat />,
    title: "24/7 tech support live chat",
  },
];

export { whyConfig };
