const devicePageConfig = {
  "title": "What kind of device do you have?",
  "description": "Feel free to select more than one.",
  "items": [
    {
      "title": "iPhone or Android Phone",
      "value": "phone"
    },
    {
      "title": "Mac or PC",
      "value": "computer"
    },
    {
      "title": "Tablet",
      "value": "tablet"
    }
  ],
  "button": "Next",
};

export { devicePageConfig };
