import { navConfig } from "./config";
import { Link } from "react-router-dom";

import cn from "classnames";
import styles from "./Footer.module.scss";

const Footer = ({ className }) => {
  const { REACT_APP_SHOP_EMAIL_SUPPORT, REACT_APP_DOMAIN_PROD } = process.env;

  return (
    <footer className={cn(styles["footer"], className)}>
      <div className={cn("wrapper", styles["footer__wrapper"])}>
        <div className={styles["footer__content"]}>
          <div className={styles["footer__content-left"]}>
            <Link to="/">
              <img
                className={styles["footer__logo"]}
                src={"/images/logo-white.svg"}
                alt="Secure my phone - logo"
              />
            </Link>
            <span className={styles["footer__content-address"]}>
              3524 Silverside Road Suite 35B, Wilmington, County of New Castle,
              19810-4929, Delaware, USA
            </span>
          </div>
          <div className={styles["footer__content-right"]}>
            <p>
              To contact us, please email{" "}
              <a href={`mailto:${REACT_APP_SHOP_EMAIL_SUPPORT}`}>{REACT_APP_SHOP_EMAIL_SUPPORT}</a>
            </p>
            <NavFooter />
          </div>
        </div>

        <div className={styles["footer__content-bottom"]}>
          <div>
            <h4 className={styles["title"]}>Secure online payment:</h4>
            <div className={styles["footer__payment-list"]}>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/visa.png"}
                  alt="visa"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/mastercard.png"}
                  alt="mastercard"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/american.png"}
                  alt="american express"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/discover.png"}
                  alt="discover"
                />
              </figure>
            </div>
          </div>

          <div>
            <h4 className={styles["title"]}>Approved by:</h4>
            <div className={styles["footer__payment-list"]}>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/jcb.png"}
                  alt="jcb"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/wire.png"}
                  alt="wire"
                />
              </figure>
              <figure>
                <img
                  width="48"
                  height="32"
                  src={"/images/common/pay-icon/debit.png"}
                  alt="direct debit"
                />
              </figure>
              <figure>
                <img
                  width="101"
                  height="32"
                  src={"/images/common/pay-icon/credit.png"}
                  alt="CreditCardSafe"
                />
              </figure>
              <figure>
                <img
                  width="66"
                  height="32"
                  src={"/images/common/pay-icon/pci.png"}
                  alt="pci"
                />
              </figure>
            </div>
          </div>
          <div>
            <p className={styles["footer__copy"]}>
              © {new Date().getFullYear()} {REACT_APP_DOMAIN_PROD}.
              <br />
              All trademarks are the property of their respective owners.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const NavFooter = () => {
  return (
    <div className={styles["footer__nav"]}>
      <nav>
        <ul className={styles["footer__list"]}>
          {navConfig.map(({ href, text }) => {
            return (
              <li key={href} className={styles["footer__list-item"]}>
                <Link
                  className={styles["footer__list-link"]}
                  to={href}
                  aria-label={text}
                >
                  {text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Footer;
