const navConfig = [
  {
    href: '/#product',
    text: 'Product',
    moveToId: 'product',
  },
  {
    href: '/#next-steps',
    text: 'Next steps',
    moveToId: 'next-steps',
  },
  {
    href: '/#why-us',
    text: 'Why us',
    moveToId: 'why-us',
  },
  {
    href: '/#features',
    text: 'Features',
    moveToId: 'features',
  }
];

export { navConfig };
