import React from 'react';
import { Helmet } from "react-helmet-async";
import PriceSection from "../components/Funnel/PriceSection";

const Pricing = () => {
  return (
    <>
      <Helmet>
        <title>Choose your plan</title>
        <meta
          name="description"
          content="Choose your plan"
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PriceSection />
    </>
  );
};

export default Pricing;
