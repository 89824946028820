import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Nav } from "../Nav";
import { Button } from "../UI";

import cn from "classnames";
import styles from "./Header.module.scss";
import SimpleHeader from "./SimpleHeader";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const { pathname } = useLocation();
  const isSimpleHeader = ["/device", "/pricing"].includes(pathname);

  const handleNavOpen = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    if (isNavOpen && !isMobile) {
      setIsNavOpen(false);
    }
  }, [isNavOpen, isMobile]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const body = document.body;
      body.classList.toggle("disabled-scroll", isNavOpen);
    }
  }, [isNavOpen]);

  return isSimpleHeader ? (
    <SimpleHeader />
  ) : (
    <header className={styles["header"]}>
      <div className={cn("wrapper", styles["header__wrapper"])}>
        <Link to="/" className={styles["header__logo"]}>
          <img src={"/images/logo.svg"} width="124" height="33" alt="Secure my phone - logo" />
        </Link>

        <Nav isNavOpen={isNavOpen} onNavClose={() => setIsNavOpen(false)} />

        <Button
          className={styles["header__button"]}
          to="/device"
          rel="nofollow"
        >
          Get started
        </Button>

        <button
          className={cn(
            "hamburger",
            "hamburger--spin",
            styles["header__hamburger"],
            { "is-active": isNavOpen },
          )}
          type="button"
          aria-expanded={isNavOpen}
          aria-label={isNavOpen ? "closeTheMenu" : "openTheMenu"}
          onClick={handleNavOpen}
        >
          <span className={cn("hamburger-box")}>
            <span className={cn("hamburger-inner")}></span>
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
